import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-virtualized/styles.css';
import { App } from 'components/App';
import reportWebVitals from './reportWebVitals';
import { RootStore, RootStoreContext } from 'stores/RootStore';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as FullStory from '@fullstory/browser';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'utils/createTheme';
import { initFeatureFlagger } from '@bowery-valuation/feature-flagger-client';

(async () => {
  if (process.env.REACT_APP_FULLSTORY_ORG_ID) {
    FullStory.init({
      orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
    });
  }

  if (process.env.REACT_APP_FRESHCHAT_TOKEN) {
    const fcWidget = (window as any).fcWidget;
    if (fcWidget) {
      fcWidget.init({
        token: process.env.REACT_APP_FRESHCHAT_TOKEN,
        host: 'https://boweryvaluation.freshchat.com',
      });
    }
  }

  if (process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID) {
    await initFeatureFlagger(
      process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID,
      null,
    );
  }

  const rootStore = new RootStore();
  rootStore.init();

  ReactDOM.render(
    <React.StrictMode>
      <RootStoreContext.Provider value={rootStore}>
        <LocalizationProvider adapterLocale="en" dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </LocalizationProvider>
      </RootStoreContext.Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
